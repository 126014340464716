<template>
  <div>
    <vx-card
      ><h3 class="mb-5 text-info">{{ $t("Editar promoción") }}</h3>
      <div class="grid grid-cols-2 gap-5">
        <div class="w-full col-span-full">
          <label for="promotion-name">
            {{ $t("Nombre de la promoción") }}:</label
          >
          <vs-input
            v-model="form.promotion_name"
            id="promotion-name"
            class="w-full"
          />
          <p
            v-if="errors.promotion_name"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.promotion_name }}
          </p>
        </div>
        <div class="col-span-full">
          <label for="promotion-description">{{ $t("Descripción") }}:</label>

          <vs-textarea
            v-model="form.description_promotion"
            class="w-full mb-0"
            id="promotion-description"
          />
          <p
            v-if="errors.description_promotion"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.description_promotion }}
          </p>
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Fecha de inicio") }}:</label>
          <vs-input
            type="date"
            v-model="form.date_init"
            id="promotion-name"
            class="w-full"
            :min="dateToString(new Date())"
          />
          <p
            v-if="errors.date_init"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.date_init }}
          </p>
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Hora de inicio") }}:</label>
          <vSelect
            id="promotion-rules"
            :options="optionHours"
            :reduce="(hour) => hour.id"
            v-model="form.init_hour"
            class="w-full"
          >
          </vSelect>
          <p
            v-if="errors.init_hour"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.init_hour }}
          </p>
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Fecha de fin") }}:</label>
          <vs-input
            type="date"
            v-model="form.date_end"
            id="promotion-name"
            class="w-full"
            :min="minFinalDate"
          />
          <p
            v-if="errors.date_end"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.date_end }}
          </p>
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Hora de fin") }}:</label>

          <vSelect
            id="promotion-rules"
            :options="optionHours"
            :reduce="(hour) => hour.id"
            v-model="form.end_hour"
            class="w-full"
          />
          <p
            v-if="errors.end_hour"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.end_hour }}
          </p>
        </div>
      </div>
      <div class="mt-10 flex justify-end gap-5">
        <vs-button
          :color="'#FFF'"
          :text-color="'#666'"
          class="rounded-full"
          @click.native="$router.go(-1)"
        >
          {{ $t("Volver") }}
        </vs-button>
        <vs-button
          class="rounded-full"
          @click.native="editPromotion"
          :disabled="loading"
        >
          {{ $t("Guardar") }}
        </vs-button>
      </div></vx-card
    >
  </div>
</template>
<script>
import VSelect from "vue-select";
import http from "@/http/promotions-http-client.js";
import hours from "@/assets/utils/hours";
export default {
  name: "EditPromotionPage",
  components: {
    VSelect,
  },

  data() {
    return {
      form: {
        promotion_name: "Decuentos Hogar",
        description_promotion: "Decuentos Hogar",
        date_init: "",
        date_end: "",
        init_hour: "",
        end_hour: "",
      },
      errors: {
        promotion_name: "",
        description_promotion: "",
        date_init: "",
        date_end: "",
        init_hour: "",
        end_hour: "",
      },
      loading: false,
      optionHours: hours,
    };
  },
  async created() {
    await this.getPromotion();

    this.$store.dispatch("updatePageTitle", {
      title: this.$t("Promociones"),
      subtitle: this.$t("Edita una promoción"),
    });
  },
  computed: {
    minFinalDate() {
      return this.form.date_init
        ? this.form.date_init
        : this.dateToString(new Date());
    },
  },
  methods: {
    async getPromotion() {
      const id = this.$route.params.id || "";
      this.loading = true;
      const response = await http.get(`api/CustonPromotionsSh/${id}`);
      this.loading = false;
      if (!response || response.status !== 200) {
        this.$router.go(-1);
        return;
      }
      const {
        promotion_name,
        description_promotion,
        date_init,
        date_end,
        init_hour,
        end_hour,
      } = response.data;
      this.form = {
        promotion_name,
        description_promotion,
        date_init,
        date_end,
        init_hour,
        end_hour,
      };
    },
    async editPromotion() {
      if (this.isInvalidForm()) return;
      const id = this.$route.params.id || "";
      this.loading = true;
      const response = await http.put(`api/CustonPromotions/${id}`, {
        ...this.form,
      });
      this.loading = false;
      if (!response || response.status !== 201) return;
      this.$router.go(-1);
    },
    isInvalidForm() {
      this.cleanErrors();
      let invalid = false;
      for (const input in this.errors) {
        if (this.form[input] === "" || this.form[input] === null) {
          this.errors[input] = this.$t("Campo requerido");
          invalid = true;
        }
        if (
          input === "end_hour" &&
          this.form.date_init === this.form.date_end &&
          this.form.init_hour >= this.form.end_hour
        ) {
          this.errors[input] = this.$t(
            "La hora de inicio debe ser mayor a la hora final"
          );
          invalid = true;
        }
      }
      return invalid;
    },
    cleanErrors() {
      for (const error in this.errors) {
        this.errors[error] = "";
      }
    },
    dateToString(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      if (day < 10) {
        day = "0" + day;
      }

      if (month < 10) {
        month = `0${month}`;
      }
      const dateString = year + "-" + month + "-" + day;

      return dateString;
    },
  },
};
</script>
